(function ($) {
    'use strict';

    var wrapper = document.getElementById('wrapper');
    if (!wrapper.classList.contains("ticket-page")) {
        // not the right page, rest of the script isn't needed
        return;
    }

    const
        REQUESTVERIFICATIONTOKEN = "RequestVerificationToken",

        ALLOWEDEXTENSIONS = [
            ".jpg", ".jpeg", ".png", ".bmp",
            ".doc", ".docx", ".xlsx", ".xls", ".ppt", ".pptx",
            ".zip", ".rar",
            ".txt", ".pdf",
            ".mpg", ".mp4", ".mpeg", ".avi", ".movie", ".mov"],

        MINFILESIZE = 5,                // 5 bytes, mainly just to stop empty documents
        MAXFILESIZE = 50 * 1024000,     // 50 MB. Advertised limit is 40MB and server limit is 128MB

        CHANGE = "change";


    var forgeryToken = "",

        ticketModel = kendo.observable({


        //#region Create Ticket Page

        departmentId: -1,
        uploadedFiles: [],

        departmentIs: function (deptId) {
            return this.get('departmentId') === deptId;
        },

          //#endregion




        //#region View Ticket Page

        canClose: false,

        panelOpen: false,

        setStatus: function (swtch) {
            var that = this;

            if (that.textArea == null) {
                that.textArea = $('#message');
            }
            that.set('canClose', swtch.checked);
            that.textArea.attr('required', swtch.checked ? null : true);
        },

        showUpdatePanel: function (e) {
            preventDefault(e);

            this.set('panelOpen', true);
            $(e.target).closest(".tools").hide();
            $('#update-form').slideDown();
        }

        //#endregion
    });

    
    var uploaderExtensions = {

        _beginUploadToServer: function (e)
        {
            // Add an anti-forgery token to the header to act as an auth token
            // Upload will be denied if this is not present
            var xhr = e.XMLHttpRequest;

            if (xhr) {
                xhr.addEventListener("readystatechange", (e) => {
                    if (xhr.readyState == 1 /* OPENED */) {
                        xhr.setRequestHeader(REQUESTVERIFICATIONTOKEN, forgeryToken);
                    }
                });
            }
        },

        _beginRemoveFromServer: function (e)
        {
            // Add an anti-forgery token to the header to act as an auth token
            // Upload will be denied if this is not present
            e.headers = { REQUESTVERIFICATIONTOKEN: forgeryToken };
            e.data = { files: [] };

            for (var i = 0; i < e.files.length; i++)
            {
                e.data.files.push( [e.files[i].serverId, e.files[i].name] );
            }
        },

        _onSuccess: function (e)
        {
            if (e.response && e.response.Success)
            {
                var uploadWidget = e.sender,
                    clientFiles = e.files;

                for (var i = 0; i < e.response.Files.length; i++)
                {
                    var serverFileResult = e.response.Files[i],
                        uploadFile = null;

                    // go through the files in the payload (request) and match
                    // up by filename
                    for (var i2 = 0; i2 < clientFiles.length; i2++) {
                        if (clientFiles[i2].name === serverFileResult.Name) {
                            uploadFile = clientFiles[i2];
                            break;
                        }
                    }

                    if (uploadFile !== null)
                    {
                        if (serverFileResult.Success)
                        {
                            if (e.operation === "upload")
                            {
                                uploadFile['serverId'] = serverFileResult.ID;

                                // make a copy of the file data and pass it to the observable model
                                var objCopy = $.extend(true, {}, uploadFile);
                                delete objCopy.rawFile;
                                delete objCopy.uid;

                                ticketModel.uploadedFiles.push(objCopy);
                            }
                            else {
                                // operation == "remove"
                                // find the entry in the uploaded files storage
                                // and remove it
                                var savedFiles = ticketModel.get('uploadedFiles');

                                for (var sfi = 0; sfi < savedFiles.length; sfi++)
                                {
                                    if (savedFiles[sfi].serverId === uploadFile.serverId)
                                    {
                                        ticketModel.uploadedFiles.splice(sfi, 1);
                                    }
                                }
                            }

                            // update the session storage
                            sessionStorage.uploadedFiles = JSON.stringify(ticketModel.uploadedFiles.toJSON());

                            // refresh the form with the updated template stuff
                            ticketModel.uploadedFiles.trigger(CHANGE);
                        }
                        else
                        {
                            // server side operation failed, put an error in the widgets file data
                            uploadFile['error'] = serverFileResult.Message;
                            uploadFile['validationErrors'] = [serverFileResult.Localised];

                            // and update the dom to show the error
                            uploaderExtensions._swapFileInDom(uploadWidget, uploadFile);
                        }
                    }
                }
            }
        },

        _swapFileInDom: function (uploadWidget, uploadFile)
        {
            var fileList = $('.k-upload-files', uploadWidget.wrapper),
                data = { fileNames: [uploadFile] },
                uid = uploadFile.uid;

            // remove the old one
            $("[data-uid=\"" + uid + "\"]", fileList).remove();

            // add the updated entry
            var templateHtml = uploadWidget._prepareDefaultSingleFileEntryTemplate(data);
            templateHtml.attr(kendo.attr('uid'), uid).appendTo(fileList).data(data);
            //$('.k-progress', templateHtml).width('100%');

            // add the remove button
            uploadWidget._fileAction(templateHtml, 'remove', false);
        }

    };

    $(function () {
        kendo.bind(document.getElementById('content'), ticketModel);

        forgeryToken = $("input[name='gxrf']").val();

        var uploadedFiles = [],
            attachmentsElement = $('#attachments');

        if (attachmentsElement.length === 1)
        {
            if (sessionStorage.uploadedFiles !== undefined)
            {
                uploadedFiles = JSON.parse(sessionStorage.uploadedFiles); 
                ticketModel.set('uploadedFiles', uploadedFiles );
            }

            attachmentsElement.show()
                .find('#inFile').kendoUpload(
                {
                    multiple: true,
                    async: {
                        saveUrl: "/attachments/save",
                        removeUrl: "/attachments/remove",
                        autoUpload: true
                    },

                    files: uploadedFiles,

                    validation: {
                        allowedExtensions: ALLOWEDEXTENSIONS,
                        minFileSize: MINFILESIZE,
                        maxFileSize: MAXFILESIZE

                    },

                    upload: uploaderExtensions._beginUploadToServer,
                    remove: uploaderExtensions._beginRemoveFromServer,
                    success: uploaderExtensions._onSuccess
                });
        }
    });

})(jQuery);



