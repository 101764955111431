
    (function ($) {
        var kendo = window.kendo,
            ui = kendo.ui,
            Widget = ui.Widget,
            support = kendo.support,

            styles = {
                widget: 'g-tileselect',
                disabled: 'k-state-disabled',
                active: 'k-state-active'
            };

        const
            WIDGETNAME = "PickableTile",
            NS = '.' + WIDGETNAME,

            RADIOSELECTOR = '[type=radio]',
            TILESELECTOR = '.tile',

            DISABLED = 'disabled',
            ARIA_DISABLED = 'aria-disabled',
           
            CHECKED = 'checked',
            ARIA_selectED = 'aria-checked',

            CLICK = support.click + NS,
            CHANGE = 'change',
            TOUCHEND = support.pointers ? 'pointerup' : 'touchend',
            KEYDOWN = 'keydown' + NS,
            LABELIDPART = '_label';


        var widget = Widget.extend({
            init: function (element, options) {
                var that = this;

                Widget.fn.init.call(that, element, options);

                //options = that.options;
                element = that.element[0];

                this._required = false;
                that._value = null;

                that._tiles = that.element.find(TILESELECTOR);
                that._radios = [];

                that._tiles.each((_, tileElement) =>
                {
                    var $tileElement = $(tileElement),
                        radio = $tileElement.find(RADIOSELECTOR);

                    // save references for easy discovery later on
                    that._radios.push(radio);
                    radio.data('tile', $tileElement);
                    $tileElement.data('radio', radio);

                    if (radio.attr('required') !== 'undefined') {
                        that._required = true;
                    }

                    // if radio is checked/selected then select it
                    if (radio.is(':' + CHECKED)) {
                        setTimeout( _ => { that._select(radio); }, 100);
                    }
                });

                that.element.addClass(styles.widget);
                that.element.on(CHANGE + NS, RADIOSELECTOR, this._change.bind(this));
                that.element.on(CLICK, TILESELECTOR, this._click.bind(this));

                that._aria();
                kendo.notify(that, kendo.ui);
            },

            setOptions: function (options) {
                var that = this;

                if (options.enabled !== undefined) {
                    that.enable(options.enabled);
                }

                //that.check(options.checked);
            },

            _aria: function () {
                //var that = this, element = that.element, wrapper = that.wrapper, id = element.attr('id'), labelFor = $('label[for="' + id + '"]'), ariaLabel = element.attr('aria-label'), ariaLabelledBy = element.attr('aria-labelledby');
                //if (ariaLabel) {
                //    wrapper.attr('aria-label', ariaLabel);
                //} else if (ariaLabelledBy) {
                //    wrapper.attr('aria-labelledby', ariaLabelledBy);
                //} else if (labelFor.length) {
                //    var labelId = labelFor.attr('id');
                //    if (!labelId) {
                //        labelId = (id || kendo.guid()) + LABELIDPART;
                //        labelFor.attr('id', labelId);
                //    }
                //    wrapper.attr('aria-labelledby', labelId);
                //}
            },

            events: [CHANGE],

            options: {
                name: WIDGETNAME,

                checked: false,
                enabled: true
            },

    
            value: function (value) {
                if (typeof value !== 'undefined') {
                    // set the value /selected radio item
                }

                return this._value;
            },

            destroy: function () {
                Widget.fn.destroy.call(this);
                this.wrapper.off(NS);
            },

            _change: function (e) {
                // this fires twice, once for the radio element losing checked and once
                // for the gaining element

                preventDefault(e);

                var $radio = $(e.currentTarget),
                    //$tile = $radio.data('tile'),
                    checked = $radio.is(':' + CHECKED);

                if (checked) {
                    that._select(radio);
                }
            },

            _click: function (e) {
                preventDefault(e);
                e.stopPropagation();

                var that = this,
                    $tile = $(e.currentTarget),
                    $radio = $tile.data('radio');

                if (that._lastChecked) {
                    that._lastChecked.removeAttr('checked');
                }

                $radio.attr("checked", true);
                that._select($radio);
            },

            _select: function ($radio) {
                var that = this,
                    newValue = $radio.val();

                if (that._lastChecked) {
                    that._lastChecked.data('tile').removeClass(styles.active);
                }

                $radio.data('tile').addClass(styles.active);

                that._lastChecked = $radio;
                that._value = newValue;

                that.trigger(CHANGE, { value: newValue });
            }

        });

        ui.plugin(widget);

    }(window.kendo.jQuery));
    
