

(function ($) {
    "use strict";

    //$("[role=tabstrip]").kendoTabStrip();


    var expandingTextAreas = $('textarea.autoexpand');

    if (expandingTextAreas.length == 1)
    {
        expandingTextAreas.on('focus', e => {
            var textarea = e.currentTarget;

            textarea.style.minHeight = "90px";
            textarea.style.height = (textarea.scrollHeight + 10) + "px";
        });

        expandingTextAreas.on('keypress', e => {
            var textarea = e.currentTarget,
                $textarea = $(textarea),
                maxHeight = parseInt($textarea.css('maxHeight')),
                lineHeight = parseInt($textarea.css('lineHeight'));

            if ( isNaN(maxHeight) ) {
                maxHeight = 350;
            }

            if ( isNaN(lineHeight) ) {
                lineHeight = 20;
            }

            if (textarea.dataset.maxHeight !== true)
            {
                if (textarea.scrollTop !== 0) {
                    textarea.style.height = (textarea.scrollHeight + (lineHeight * 4) ) + "px";
                }

                if (parseInt(textarea.style.height) >= maxHeight) {
                    textarea.style.overflow = "auto";
                    textarea.dataset.maxHeight = true;
                }
            }

        });
    }



}) (jQuery);